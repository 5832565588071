<style lang="scss" scoped>
.wrap {
  background: #fff;
  padding: 10px 15px 15px 15px;
}
</style>
<template>
  <div class="wrap">
    <el-tabs v-model="currentTab">
      <el-tab-pane  label="NS" name="NS" v-if="hasMenu('NS')" lazy>
        <el-select v-model="currentType" filterable="" placeholder="请选择" size="mini">
          <el-option v-for="item in nsList" :key="item.label" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <div class="main">
          <component :key="currentType" :is="componentObj[currentType]" :libraryType="currentType" />
        </div>
      </el-tab-pane>
      <el-tab-pane label="Sport Rules" v-if="hasMenu('sportRules')" name="sportRules" lazy >
        <sportRules/>
      </el-tab-pane>
    </el-tabs>
    <!-- <el-tabs v-model="currentTab">
      <el-tab-pane label="Sports" name="Sports" >
        <Sports libraryType="1" />
      </el-tab-pane>
      <el-tab-pane label="Region" name="Region" lazy>
        <Region libraryType="2" />
      </el-tab-pane>
      <el-tab-pane label="tournament" name="tournament" lazy>
        <tournament libraryType="3" />
      </el-tab-pane>
      <el-tab-pane label="Team" name="Team" lazy>
        <Team libraryType="4" />
      </el-tab-pane>
      <el-tab-pane label="Market Type" name="MarketType" lazy>
        <market-type libraryType="7" />
      </el-tab-pane>

      <el-tab-pane label="Period" name="Period" lazy>
        <period libraryType="9" />
      </el-tab-pane>
      <el-tab-pane label="Option" name="Option" lazy>
        <option-com libraryType="8" />
      </el-tab-pane>
      <el-tab-pane label="MatchPeriod" name="Match Period" lazy>
        <match-period libraryType="10" />
      </el-tab-pane>
      <el-tab-pane label="MatchStatus" name="Match Status" lazy>
        <match-status libraryType="11" />
      </el-tab-pane>
      <el-tab-pane label="TournamentPhase" name="Tournament Phase" lazy>
        <TournamentPhase libraryType="12" />
      </el-tab-pane>
      <el-tab-pane label="Player" name="Player" lazy>
        <Player libraryType="13" />
      </el-tab-pane>
      <el-tab-pane label="SR Outright Market" name="SR Outright Market" lazy>
       <market-type libraryType="14" />
      </el-tab-pane>
      <el-tab-pane label="SR Outright Option" name="SR Outright Option" lazy>
        <option-com libraryType="15" />
      </el-tab-pane>
      <el-tab-pane label="SR Outright Match" name="SR Outright Match" lazy>
        <OutrightMatch libraryType="16" />
      </el-tab-pane>
      <el-tab-pane label="Manual Outright Market" name="Manual Outright Market" lazy>
       <market-type libraryType="17" />
      </el-tab-pane>
      <el-tab-pane label="Manual Outright Option" name="Manual Outright Option" lazy>
        <option-com libraryType="18" />
      </el-tab-pane>
      <el-tab-pane label="Cancel Reason" name="Cancel Reason" lazy>
        <option-com libraryType="19" />
      </el-tab-pane>
      <el-tab-pane label="Cancel Reason Detail" name="Cancel Reason Detail" lazy>
        <option-com libraryType="20" />
      </el-tab-pane>
      <el-tab-pane label="Result Type Group" name="Result Type Group" lazy>
        <option-com libraryType="21" />
      </el-tab-pane>
    </el-tabs> -->
  </div>
</template>
<style lang="less" scoped>
.main{
  border-top: 2px solid #f0f0f0 ;
  padding-top: 10px;
  margin-top: 10px;
}
</style>
<script>
import {
  Sports,
  Region,
  Team,
  tournament,
  // Event,
  // EventType,
  OutrightMatch,
  MarketType,
  Period,
  Option as OptionCom,
  MatchPeriod,
  MatchStatus,
  TournamentPhase,
  Player,
  sportRules
} from '../international'
import {langData} from '@/utils/constants'
import {mapGetters} from 'vuex'
export default {
  name: 'International',
  data() {
    return {
      currentTab: 'NS',
      currentType: '1',
      nsList: [
        {
          label: 'Sports',
          value: '1'
        },
        {
          label: 'Region',
          value: '2'
        },
        {
          label: 'tournament',
          value: '3'
        },
        {
          label: 'Team',
          value: '4'
        },
        {
          label: 'MarketType',
          value: '7'
        },
        {
          label: 'Period',
          value: '9'
        },
        {
          label: 'Option',
          value: '8'
        },
        {
          label: 'MatchPeriod',
          value: '10'
        },
        {
          label: 'MatchStatus',
          value: '11'
        },
        {
          label: 'TournamentPhase',
          value: '12'
        },
        {
          label: 'Player',
          value: '13'
        },
        {
          label: 'SR Outright Market',
          value: '14'
        },
        {
          label: 'SR Outright Option',
          value: '15'
        },
        {
          label: 'SR Outright Match',
          value: '16'
        },
        {
          label: 'Manual Outright Market',
          value: '17'
        },
        {
          label: 'Manual Outright Option',
          value: '18'
        },
        {
          label: 'Cancel Reason',
          value: '19'
        },
        {
          label: 'Cancel Reason Detail',
          value: '20'
        },
        {
          label: 'Result Type Group',
          value: '21'
        },
        {
          label: 'Tips',
          value: '44'
        }
      ],
      componentObj: {
        1: 'Sports',
        2: 'Region',
        3: 'tournament',
        4: 'Team',
        7: 'MarketType',
        9: 'period',
        8: 'OptionCom',
        10: 'MatchPeriod',
        11: 'MatchStatus',
        12: 'TournamentPhase',
        13: 'Player',
        14: 'MarketType',
        15: 'OptionCom',
        16: 'OutrightMatch',
        17: 'MarketType',
        18: 'OptionCom',
        19: 'OptionCom',
        20: 'OptionCom',
        21: 'OptionCom',
        44: 'MarketType'
      }
    }
  },
  provide: {
    fixHeight: 130,
    langData: langData,
    rules: {
      'language.ENG': [
        {
          message: 'Please typing in ENG',
          required: true
        }
      ]
    }
  },
  components: {
    sportRules,
    Sports,
    Region,
    Team,
    tournament,
    // Event,
    // EventType,
    OutrightMatch,
    MarketType,
    Period,
    OptionCom,
    MatchPeriod,
    MatchStatus,
    TournamentPhase,
    Player
  },
   computed: {
    ...mapGetters(['functionInfoList'])
   },
  methods: {
     hasMenu(name) {
      const key = `TMC/Page/international/${name}` 
      return !this.isOutright && this.functionInfoList.some((item) => {
        return (
          item.privilegeMark === key &&
          item.featureStatus !== 3
        )
      })
    },
  }
}
</script>
